import IconArrowShort from 'aac-components/components/Icons/Arrow/IconArrowShort';
import {BREAKPOINT, MAX_WIDTH_PX} from 'aac-components/utils/styles';
import Link from 'next/link';
import React, {useContext} from 'react';
import AppContext from '../AppContext';
import Carousel from 'aac-components/components/Carousel';

const TestimonialsSection = () => {
    const {isMobile} = useContext(AppContext);

    return (
        <section>
            <div className="header">
                <h2>Alumni Testimonials</h2>
                <Link href="/reviews" legacyBehavior>
                    <a title="View All Reviews">
                        <span>View All Reviews</span>
                        <span>
                            <IconArrowShort fill="var(--interactive)" rotate="-90" />
                        </span>
                    </a>
                </Link>
            </div>
            <div className="testimonials">
                {isMobile ? (
                    <Carousel
                        slides={testimonials.map((item) => (
                            <TestimonialsCard {...item} />
                        ))}
                    />
                ) : (
                    <>
                        {testimonials.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <TestimonialsCard {...item} />
                                </React.Fragment>
                            );
                        })}
                    </>
                )}
            </div>
            <style jsx>
                {`
                    section {
                        margin: 0 auto;
                        max-width: ${MAX_WIDTH_PX};
                        padding: 64px 15px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        section {
                            padding: 120px 0;
                        }
                    }
                    .header {
                        display: block;
                        text-align: center;
                        margin-bottom: 40px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .header {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            text-align: left;
                        }
                    }
                    .header > a {
                        display: flex;
                        font-weight: bold;
                        text-decoration: none;
                        margin: 0 auto;
                        max-width: fit-content;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .header > a {
                            margin: 0;
                        }
                    }
                    .header > a > span:nth-child(1) {
                        margin-right: 8px;
                    }

                    @media screen and (min-width: ${BREAKPOINT}) {
                        .testimonials {
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                            grid-gap: 15px;
                        }
                    }
                `}
            </style>
        </section>
    );
};
export default TestimonialsSection;

const testimonials = [
    {
        review: "AAC saved my life. 3rd time was the charm. Desert Hope in Las Vegas Nevada was the support I needed. I'm now in my last year for my AAS in Advanced Behavioral Health Sciences with Honors. I hope to one day pay it forward back at DH where they helped me reclaim my life. I absolutely recommend them to anyone looking to stop the crazy merry-go-round that is the disease of addiction.",
        facility: 'Desert Hope',
        source: 'Facebook',
    },
    {
        review: 'Looking back at my past and being able to examine my negative behaviors has been a blessing since I arrived at Oxford Resolutions sober living facility, and I wouldn’t change it for anything in the world. One day at a time is a real thing and it really works when I allow myself to live in the moment. I recommend Oxford treatment center resolutions to anyone wanting a fresh start to life.',
        facility: 'Oxford',
        source: 'Rehabs.com',
    },
    {
        review: 'When I walked into the Greenhouse I felt a little skeptical, but after just 1 week I found I was just where I needed to be! The staff was truly invested in my recovery and the facility was everything I needed and more. Everyone from the house keepers to the private therapist were there to help me on my road to a renewed me! I had set goals for what I felt I needed from my experience. ',
        facility: 'Greenhouse',
        source: 'Google',
    },
];

const TestimonialsCard = (props) => {
    return (
        <div className="testimonials__card">
            <div className="testimonials__card--quote">
                <svg width="14" height="10" viewBox="0 0 14 10" fill="none">
                    <path
                        d="M0.981534 9.3999V6.63854C0.981534 5.85445 1.13494 5.05331 1.44176 4.23513C1.74858 3.41695 2.15341 2.64564 2.65625 1.92121C3.15909 1.19678 3.70455 0.591662 4.29261 0.105868L6.69602 1.5249C6.21875 2.2749 5.8267 3.05899 5.51989 3.87717C5.22159 4.69536 5.07244 5.60729 5.07244 6.61297V9.3999H0.981534ZM7.4375 9.3999V6.63854C7.4375 5.85445 7.59091 5.05331 7.89773 4.23513C8.20455 3.41695 8.60938 2.64564 9.11222 1.92121C9.61506 1.19678 10.1605 0.591662 10.7486 0.105868L13.152 1.5249C12.6747 2.2749 12.2827 3.05899 11.9759 3.87717C11.6776 4.69536 11.5284 5.60729 11.5284 6.61297V9.3999H7.4375Z"
                        fill="#1D2758"
                    />
                </svg>
            </div>
            <div
                className="testimonials__card--review"
                dangerouslySetInnerHTML={{__html: props?.review}}
            />
            <div className="testimonials__card--byline">
                <span
                    dangerouslySetInnerHTML={{
                        __html: `${props?.facility} Alumni`,
                    }}
                />
                <span>|</span>
                <span dangerouslySetInnerHTML={{__html: props?.source}} />
            </div>
            <style jsx>
                {`
                    .testimonials__card {
                        background: var(--secondary-100);
                        border: 1px solid var(--secondary-300);
                        padding: 40px;
                        border-radius: 8px;
                        position: relative;
                    }
                    .testimonials__card--quote {
                        margin-bottom: 16px;
                    }
                    .testimonials__card--review {
                        font-size: 16px;
                        line-height: 24px;
                        margin-bottom: 40px;
                    }
                    .testimonials__card--byline {
                        font-weight: bold;
                        position: absolute;
                        bottom: 40px;
                    }
                    .testimonials__card--byline > span:nth-child(2) {
                        margin: 0 4px;
                    }
                `}
            </style>
        </div>
    );
};
